<template>
  <div class="main-page">
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 xl:col-12 sm:col-12 xs:col-12 comp-grid">
            <div>
              <form ref="observer" tag="form" @submit.prevent="validateForm()" @reset="resetForm">
                <div>
                  <table class="p-datatable-table" style="width: 100%">
                    <tbody>
                      <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                        <div class="card nice-shadow-5">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("name_of_institution") }} *
                              </div>
                              <div class="">
                                <InputText ref="ctrlname_of_institution" v-model.trim="formData.name_of_institution"
                                  :label="$t('name_of_institution')" type="text"
                                  :placeholder="$t('name_of_institution')" class="w-full"
                                  :class="getErrorClass(row, 'name_of_institution')">
                                </InputText>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("from") }} *
                              </div>
                              <div class="">
                                <Dropdown class="w-full" :class="getErrorClass(row, 'from')" ref="ctrlfrom"
                                  v-model="formData.from" :options="$utils.years(formData.from, null, dob_year)"
                                  :label="$t('from')" :placeholder="$t('select')">
                                </Dropdown>
                              </div>
                            </div>
                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("to") }} *
                              </div>
                              <div class="">
                                <Dropdown class="w-full" :class="getErrorClass(row, 'to')" ref="ctrlto"
                                  v-model="formData.to" :options="$utils.years(formData.to, null, dob_year)"
                                  :label="$t('to')" :placeholder="$t('select')">
                                </Dropdown>
                              </div>
                            </div>

                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("tertiary_programme") }} *
                              </div>
                              <div class="">
                                <InputText ref="ctrlprogramme" v-model.trim="formData.programme"
                                  :label="$t('tertiary_programme')" type="text" :placeholder="$t('tertiary_programme')"
                                  class="w-full" :class="getErrorClass(row, 'programme')">
                                </InputText>
                                <small class="text-xs text-primary mx-1">Eg. BSc.
                                  Information Technology</small>
                              </div>
                            </div>

                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("degree_awarded") }} *
                              </div>
                              <div class="">
                                <Dropdown class="w-full" :class="getErrorClass(row, 'degree_awarded')
                                  " optionLabel="label" optionValue="value"
                                  ref="ctrldegree_awarded" v-model="formData.degree_awarded"
                                  :options="$menus.tertiary_degree_list" :label="$t('degree_awarded')"
                                  :placeholder="$t('select')">
                                </Dropdown>
                              </div>
                            </div>

                            <div class="col-12 md:col-6">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("degree_class") }} *
                              </div>
                              <div class="">
                                <Dropdown class="w-full" :class="getErrorClass(row, 'degree_class')" optionLabel="label"
                                  optionValue="value" ref="ctrldegree_class" v-model="formData.degree_class"
                                  :options="$menus.tertiary_degree_classItems" :label="$t('degree_class')"
                                  :placeholder="$t('select')">
                                </Dropdown>
                              </div>
                            </div>

                            <div class="col-12 md:col-6">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{ $t("Upload certified copy of certificate") }} *
                              </div>
                              <small class="text-xs text-primary">{{
                                $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                              }} .pdf. Max number of file uploads is 2 </small>
                              <br>
                              <div class="mt-2">
                                <Uploader :auto="true" :fileLimit="2" :class="getErrorClass(row, 'certificate_url')"
                                  :maxFileSize="10000000" accept=".pdf" :multiple="false" style="width: 100%"
                                  :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/certificate_url" v-model="formData.certificate_url">
                                </Uploader>
                              </div>
                            </div>

                            <div class="col-12 md:col-6">
                              <div class="mt-3 mb-2 font-bold text-sm">
                                {{ $t("Upload academic transcript") }}
                              </div>
                              <small class="text-xs text-primary">{{
                                $t("the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB")
                              }} .pdf. Max number of file uploads is 2</small>
                              <br>
                              <div class="mt-2">
                                <Uploader :auto="true" :fileLimit="2" :class="getErrorClass(row, 'transcript')"
                                  :maxFileSize="10000000" accept=".pdf" :multiple="false" style="width: 100%"
                                  :label="$t('choose_files_or_drop_files_here')"
                                  upload-path="s3uploader/upload/transcript" v-model="formData.transcript">
                                </Uploader>
                              </div>
                            </div>

                          </div>
                        </div>
                        <th>
                          <Button class="p-button-text p-button-danger p-button-rounded p-button-sm"
                            v-if="arrFormData.length > minRowCount" @click="removeFormRow(row)" icon="pi pi-times" />
                        </th>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="100" class="text-left">
                          <div class="flex justify-content-center mb-3">
                            <div class="">
                              <Button v-if="arrFormData.length < maxRowCount" @click="addFormRow()" icon="pi pi-plus"
                                :label="$t('add_another_institution')"
                                class="p-button-info p-button-outlined p-button-sm px-3 py-2 mt-3" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div v-if="showSubmitButton" class="text-center q-my-md">
                  <Button type="submit" :label="$t('submit')" icon="pi pi-send" :loading="saving" />
                </div>
              </form>
              <slot :submit="submit" :saving="saving"></slot>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddPageMixin } from "../../mixins/addpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "pgInstitutionsAttendedPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "pgInstitutionsAttendedPage",
    },
    maxRowCount: {
      type: Number,
      default: 4,
    },
    minRowCount: {
      type: Number,
      default: 1,
    },
    institutionsattended: { type: String, default: "[]" },
    dobYear: {
      type: Number,
      default: 1950,
    },
  },
  data() {
    return {
      arrFormData: [
        {
          name_of_institution: "",
          from: "",
          to: "",
          programme: "",
          degree_awarded: "",
          degree_class: "",
          transcript: "",
          certificate_url: "",
        },
      ],
      other: "OTHER",
      yes: "Yes",
      no: "No"
    };
  },
  validations() {
    let arrFormData = {
      $each: helpers.forEach({
        name_of_institution: { required },
        from: { required },
        to: { required },
        programme: { required },
        degree_awarded: { required },
        degree_class: { required },
        certificate_url: { required },
        transcript: {},
      }),
    };
    return { arrFormData };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("institution_attended");
      },
    },
  },
  methods: {
    ...mapActions("applicants", ["updateAcademicData"]),
    validateForm() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      return isFormValid;
    },
    addFormRow() {
      let newFormData = {
        name_of_institution: "",
        from: "",
        to: "",
        programme: "",
        degree_awarded: "",
        degree_class: "",
        transcript: "",
        certificate_url: "",
      };
      this.arrFormData.push(newFormData);
    },
    removeFormRow(index) {
      this.arrFormData.splice(index, 1);
    },
    resetForm() {
      this.arrFormData = [
        {
          name_of_institution: "",
          from: "",
          to: "",
          programme: "",
          degree_awarded: "",
          degree_class: "",
          transcript: "",
          certificate_url: "",
        },
      ];
      this.updateFormData();
    },
    getErrorClass(row, field) {
      let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
      return { "p-invalid": errors.length && this.submitted };
    },
    getInstitutionsAttended() {
      if (this.institutionsattended) {
        let institutionsattended = JSON.parse(this.institutionsattended)
        this.arrFormData = institutionsattended;
      }
      if (this.arrFormData.length < 1) {
        this.addFormRow();
      }
      this.dob_year = this.dobYear;
    },
  },
  watch: {},
  async mounted() {
    this.getInstitutionsAttended();
  },
  async created() { },
};
</script>
<style scoped></style>